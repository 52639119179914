import { throwError as observableThrowError, Subject, throwError, Subscription } from 'rxjs';
import { catchError, takeUntil, map } from 'rxjs/operators';
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DataApiService } from '../../../services/data-api/data-api.service';
import { DownloadsService } from '../../../services/downloads/downloads.service';
import { Notification, NotificationsService } from '../../../services/notifications/notifications.service';

import { FileUploadService } from '../../../services/file-upload/file-upload.service';
import { MastersService } from '../../../services/masters/masters.service';

@Component({
  selector: 'dialog-file-upload',
  templateUrl: './dialog-file-upload.component.html',
  styleUrls: ['./dialog-file-upload.component.scss']
})
export class DialogFileUploadComponent implements OnInit, OnChanges {
  @ViewChild('fileinput', { static: false }) inputElement: ElementRef;
  @Input() title: string;
  @Input() id: string;

  @Output() uploadFileEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCloseAction: EventEmitter<string> = new EventEmitter<string>();

  retributiveYear: string;
  loading: boolean = true;

  uploadFile: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  uploadState: UIUploadState = new UIUploadState();
  uploadStateSub: Subscription;

  pathOptions: any[] = [];
  s3Files: any[] = [];
  s3FileToDelete: any;
  s3SelectedMasterdata: any;
  notFoundMsg: string = "";

  masterToShowFiles = null;
  displayed = {};
  formErrors = {};

  uploadView: string = 'masterdata';
  file: File;
  allDeselected = false;
  queryString: string;
  textEmptySearch: boolean;
  iconSearch: string;
  fileUpload: File;
  filePath: string;
  constructor(
    private dataApiService: DataApiService,
    private downloadsService: DownloadsService,
    private ref: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    private fileUploadService: FileUploadService,
    private mastersService: MastersService,
    private cdr: ChangeDetectorRef
  ) {
    this.iconSearch = 'search';
  }

  ngOnInit() {
    this.mastersService.getRetributiveYear().subscribe((year)=> {
      this.retributiveYear = year;
      
      this.getMasterDataPaths();
      
      this.uploadStateSub = this.fileUploadService
        .getUploadState()
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          if (response.status === 'ERROR') {
            this.uploadState.errorState(response.response);
            return throwError(response.response);
          }
          this.uploadState.successState(response);
          this.uploadFile = null;
        });
    })


  }

  getMasterDataPaths() {
    this.loading = true;
    this.fileUploadService.getMasterDataPaths()
      .pipe(
        catchError((err) => {
          console.error(err);
          return observableThrowError(err);
        }),
      )
      .subscribe((masterDataPaths) => {
        let landingOrigin = 'traspasos_at_agrupado_ot_manual';

        this.pathOptions = [
          ...masterDataPaths.prefixes.filter((e) => e !== landingOrigin),
          ...masterDataPaths.zones.map((item) => `${landingOrigin}_${item}`),
        ];
        this.fillDisplayed(this.pathOptions);
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  startUpload(fileData) {
    this.uploadState.uploadState();
    this.fileUploadService.startUpload(fileData.path, this.retributiveYear, fileData.file);
  }

  ngOnChanges(props) {
    this.fillDisplayed(this.pathOptions);
  }

  fillDisplayed(options) {
    options.forEach(item => {
      this.displayed[item] = item;
    });
  }

  objectKeys(paramObj) {
    return Object.keys(paramObj);
  }

  fileUpdate(event) {
    this.fileUpload = event.target.files[0];
  }

  resetFileUpload(event) {
    event.preventDefault();
    this.inputElement.nativeElement.value = null;
    this.fileUpload = null;
  }

  handleCloseDialog(): void {
    this.onCloseAction.emit(this.id);
  }

  validateForm() {
    this.formErrors = {};
    if (!this.fileUpload) {
      this.formErrors['fileUpload'] = true;
    }
    if (!this.filePath) {
      this.formErrors['filePath'] = true;
    }

    return Object.keys(this.formErrors).length === 0;
  }

  handleUpload() {
    if (!this.validateForm()) {
      return;
    }
    this.startUpload({
      id: this.id,
      path: this.filePath,
      file: this.fileUpload
    });
  }

  filter(value: string) {
    this.queryString = value;
    this.iconSearch = this.queryString.length > 0 ? 'cancel-search' : 'search';
    const keys = this.pathOptions
      .filter(item => item.toUpperCase().indexOf(value.toUpperCase()) > -1)
      .sort();
    this.displayed = {};
    keys.forEach(key => {
      this.displayed[key] = key;
    });
    this.textEmptySearch = Object.keys(this.displayed).length === 0;
  }

  closeInputSearch() {
    this.queryString = '';
    this.filter('');
  }

  nicename(s3Name) {
    return environment.origins.allOrigins.find((origin) => origin.origin === s3Name || origin.origin  === s3Name + '_vista')?.title || s3Name;
  }

  backToMasterdata() { 
    this.uploadView = 'masterdata' 
  }
  
  backToMasterdataFiles() { 
    this.uploadView = 'masterdataFiles' 
  }

  showMasterdataFiles(key) { 
    this.uploadView = 'masterdataFiles';
    
    this.s3Files = [];
    this.notFoundMsg = "";

    this.masterToShowFiles = key;
    this.loading = true;
    this.dataApiService.getMasterdataS3List(key).subscribe(
      response => {
        this.s3Files = response;
        this.s3SelectedMasterdata = key;
        this.notFoundMsg = response.length === 0? "No se han encontrado ficheros subidos para este maestro." : "";
        this.loading = false;
        this.ref.detectChanges();
      },
      error => {
        this.notFoundMsg = "Se ha producido un error al recuperar los ficheros de S3 de este maestro."
        this.loading = false;
        this.ref.detectChanges();
      }
    );
  }

  deleteFile() {
    this.dataApiService.deleteMasterdataFile(this.s3FileToDelete.file)
      .subscribe(
        response => {
          this.notificationsService.add({
            type: 'confirmation',
            message: 'Se ha borrado el fichero correctamente',
            fixed: false,
            popup: true
          })
          this.showMasterdataFiles(this.masterToShowFiles)
        },
        error => {
          this.notificationsService.add({
            type: 'error',
            message: 'Se ha producido un error borrando el fichero',
            fixed: false,
            popup: true
          })

        }
      );
  }

  showDeleteConfirmation(s3File: any) {
    this.uploadView = 'deleteConfirmation'
    this.s3FileToDelete = s3File;
  }

  downloadFile(s3File) {
    this.downloadsService.downloadMasterdateCsvGz(`Descargando ${s3File.nicename}`, s3File.file);
  }

  locale(date: string): string { 
    return new Date(date).toLocaleString('es-ES');
  }
  
}

export interface UIUploadState {
  uploading: boolean;
  uploadError: boolean;
  response: any;
}

export class UIUploadState {
  uploading: boolean;
  uploadError: boolean;
  response: any;

  constructor() {
    this.resetState();
  }

  uploadState() {
    this.uploading = true;
    this.uploadError = false;
    this.response = undefined;
  }

  successState(response) {
    this.uploading = false;
    this.uploadError = false;
    this.response = response;
  }

  errorState(err) {
    this.uploading = false;
    this.uploadError = true;
    this.response = err;
  }

  resetState() {
    this.uploading = false;
    this.uploadError = false;
    this.response = null;
  }
}
