import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { BdrValidationsService } from '../../services/bdr-validations/bdr-validations.service';
import { Validation, Zone } from '../../shared/interfaces/validations.interface';
import { FilterDB } from '../../services/filter/filter.service';
import { VALIDATIONS_CONFIG, ValidationConfig } from '../../../environments/common/components.conf';

import { InvestmentFilterService } from '../../services/investment-filter/investment-filter.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'bdr-validations',
  templateUrl: './validations.component.html',
  styleUrls: ['./validations.component.scss'],
})
export class ValidationsComponent implements OnInit {
  @Input() origin: string;
  @Input() scope: string;
  @Input() originTableName: string;

  validations: Validation[] = [];
  zones: Zone[] = [];
  appliedFilters = [];
  hasExtraFilters = false;
  config: ValidationConfig;

  // Applied filters
  selectedValidations: Validation[] = [];
  selectedBases: Zone[] = [];
  workState: string;
  amountMin: number;
  amountMax: number;

  constructor(
    private validationsService: BdrValidationsService,
    private investmenFilterService: InvestmentFilterService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.config = VALIDATIONS_CONFIG[this.origin];

    this.getValidations();
    this.appliedFilters = this.investmenFilterService.getFilterByOrigin(this.origin);
  }

  getValidations() {
    if (!this.config.hasExtraFilters) {
      switch (this.origin) { 
        case 'fa1_vista':
          this.validations = this.validationsService.getFormA1Validations();
          break;
        case 'fa3_vista':
          this.validations = this.validationsService.getFormA3Validations();
          break;
        case 'fa4_vista':
          this.validations = this.validationsService.getFormA4Validations();
          break;
        case 'fa5_vista':
          this.validations = this.validationsService.getFormA5Validations();
          break;
      }
      this.zones = [];

      return;
    }


    this.validationsService
      .getValidationsFilters()
      .pipe(
        map((res) => ({
          validations: res.validations,
          zones: this.mapZones(res.zones),
        })),
      )
      .subscribe((res) => {
        const { validations, zones } = res;
        this.validations = validations;
        this.zones = zones;
        this.cdr.detectChanges();
      });
  }

  private mapZones(zones) {
    const zonesKeys: string[] = Object.keys(zones);
    let mapZones: Zone[];

    mapZones = zonesKeys.map((zone) => ({
      name: zone,
      count: zones[zone].count,
      delegations: zones[zone].delegations,
    }));

    mapZones.forEach((zone) => {
      const delegationsKeys = Object.keys(zone.delegations);
      zone.delegations = delegationsKeys.map((key) => ({
        name: key,
        count: zone.delegations[key].count,
        bases: zone.delegations[key].bases,
      }));

      zone.delegations.forEach((delegations) => {
        const basesKeys = Object.keys(delegations.bases);
        delegations.bases = basesKeys.map((key) => ({
          name: key,
          id: delegations.bases[key].id,
          count: delegations.bases[key].count,
        }));
      });
    });

    return mapZones;
  }

  handleCheckedValidations(validations: Validation[]) {
    this.selectedValidations = validations;
  }

  handleSelectedZones(zones: Zone[]) {
    this.selectedBases = zones;
  }

  applyFilters() {
    let rules = [];

    let nicenames = [];
    if (this.amountMin) {
      rules.push({
        operator: 'greater_or_equal',
        field: 'importe_expediente_original',
        value: this.amountMin,
      });
      nicenames.push(`importe >= ${this.amountMin}`);
    }

    if (this.amountMax) {
      rules.push({
        operator: 'less_or_equal',
        field: 'importe_expediente_original',
        value: this.amountMax,
      });
      nicenames.push(`importe <= ${this.amountMax}`);
    }

    if (!!this.workState) {
      rules.push({
        operator: 'equal',
        field: 'obra_revisada',
        value: this.workState,
      });
      nicenames.push(`obra revisada = ${this.workState}`);
    }

    if (this.selectedBases.length > 0) {
      let baseNames = [];
      let bases = [];
      this.selectedBases.forEach((zones) =>
        zones.delegations.forEach((delegation) =>
          delegation.bases.forEach((base) => {
            baseNames.push(base.name);
            bases.push({
              value: base.id,
              field: 'cod_zona',
              operator: 'equal',
            });
          }),
        ),
      );

      nicenames.push(`zonas = ${baseNames.join(', ')}`);

      rules.push({
        condition: 'OR',
        rules: bases,
      });
    }

    if (this.selectedValidations.length > 0) {
      let validationsIds = [];
      const validations = this.selectedValidations.map((validation) => {
        validationsIds.push(validation.id);
        return {
          condition: 'OR',
          rules: [
            {
              operator: 'equal',
              field: this.config.fieldName,
              value: `${validation.id}`,
              origin: this.origin,
            },
            {
              operator: 'begins_with',
              field: this.config.fieldName,
              value: `${validation.id}|`,
              origin: this.origin,
            },
            {
              operator: 'contains',
              field: this.config.fieldName,
              value: `|${validation.id}|`,
              origin: this.origin,
            },
            {
              operator: 'ends_with',
              field: this.config.fieldName,
              value: `|${validation.id}`,
              origin: this.origin,
            },
          ],
        };
      });

      nicenames.push(`validaciones = ${validationsIds.join(', ')}`);

      rules.push({
        condition: 'AND',
        rules: validations,
      });
    }

    this.appliedFilters = this.appliedFilters.filter(
      (filter) => filter.id !== 'validations_filters',
    );

    this.appliedFilters.push(
      new FilterDB({
        filter: { condition: 'AND', rules },
        meta: { type: 'filter' },
        name: `Filtros de validaciones: ${nicenames.join('; ')}`,
        id: 'validations_filters',
      }),
    );

    this.investmenFilterService.setAppliedFilters(this.origin, this.appliedFilters);
  }

  get hasFiltersApplied() {
    return (
      this.selectedValidations.length > 0 ||
      this.selectedBases.length > 0 ||
      !!this.workState ||
      !!this.amountMin ||
      !!this.amountMax
    );
  }
}
