import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { GridProperties, GridService, GridExportData } from '../../services/grid/grid.service';
import { MastersService } from '../../services/masters/masters.service';
import { OriginService } from '../../services/origin/origin.service';
import { SchemaService } from '../../services/schema/schema.service';
import { safeDetectChanges } from '../../util';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TasksService } from '../../services/tasks/tasks.service';
import { DataApiService } from '../../services/data-api/data-api.service';
import { DEFAULT_VIEWS } from '../../../environments/common/default-views';
import { MASSIVE_EDITIONS_CONFIG } from '../../../environments/common/components.conf';
import { MatDialog } from '@angular/material/dialog';
import { InvestmentFilterService } from '../../services/investment-filter/investment-filter.service';
import { DownloadsService } from '../../services/downloads/downloads.service';
import { AsyncQueryResponse } from '../../models/query-poller';
import { AuthService } from '../../services/auth/auth.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { getExpandedItem } from '../../util';
import { MasterDataService } from '../../master-data/services/master-data.service';

@Component({
  selector: 'forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnDestroy {
  retributiveYear: string;
  currentOrigin: any = {};
  gridProperties: GridProperties;
  subscriptions: any[] = [];
  savingFlag: any;
  savingType: string;
  showModalSaving: any;
  panels: any;
  editData: any[] = [];
  editingRows: Set<number> = new Set();
  editMode = false;
  editType: string;
  formOrigins: any;
  subheaderSelector = [];
  saveGridSubscription: Subscription;
  private destroy$: Subject<any> = new Subject();
  private selectedRows = [];
  constructor(
    private gridService: GridService,
    private mastersService: MastersService,
    private originService: OriginService,
    private schemaService: SchemaService,
    private downloadsService: DownloadsService,
    private apiService: DataApiService,
    private tasksService: TasksService,
    private investmentsFiltersService: InvestmentFilterService,
    public matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    public authService: AuthService,
    private notificationService: NotificationsService,
    private masterDataService: MasterDataService
  ) {
    this.formOrigins = this.originService.getByKey('forms');
    this.subheaderSelector = [
      {
        title: 'Plan de inversiones',
        key: 'inversionplan',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.formOrigins.inversionplan.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
      {
        title: 'Circular',
        key: 'circular',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.formOrigins.circular.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
      {
        title: 'Inventario',
        key: 'inventory',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.formOrigins.inventory.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
      {
        title: 'Auditoría',
        key: 'auditoria',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.formOrigins.auditoria.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
      {
        title: 'CNMC A',
        key: 'cnmc_a',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.formOrigins.cnmc_a.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
      {
        title: 'CNMC B',
        key: 'cnmc_b',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.formOrigins.cnmc_b.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
      {
        title: 'CNMC C',
        key: 'cnmc_c',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.formOrigins.cnmc_c.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
      {
        title: 'CNMC D',
        key: 'cnmc_d',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.formOrigins.cnmc_d.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
    ];

    this.mastersService
      .getRetributiveYear()
      .pipe(takeUntil(this.destroy$))
      .subscribe((year) => {
        this.retributiveYear = year;
      });
    this.gridProperties = this.gridService.getDefaultGridProperties();
    this.gridProperties.globalEditMode = false;
    this.gridProperties.edit = false;
    this.gridProperties.bordered = true;
    this.gridProperties.reload = false;
    this.gridProperties.columnSelector = true;
    this.gridProperties.changeColumnSelection = this.changeColumnSelection.bind(this);
    this.gridProperties.editDblClick = false;
    this.gridProperties.generate = false;
    this.gridProperties.showSelectBox = true;
    this.gridProperties.selectable = true;
    this.gridProperties.columnSearch = true;
    this.gridProperties.columnSelector = true;
    this.gridProperties.editDblClick = false;
    this.gridProperties.editingRows = [];
    this.gridProperties.headerActions = [];
    this.gridProperties.currentRequestParams = {};
    this.gridProperties.schemaScope = 'forms';

    this.panels = {
      downloads: { disabled: true },
      exportCNMC: { disabled: true },
      fastFilter: { disabled: true },
      filters: { disabled: false },
      visualization: true,
      validations: false,
      manualValidations: false,
      refreshSheet: { disabled: false },
      exportF9Form: { disabled: true },
    };

    //header actions
    this.gridProperties.headerActions = [{ type: 'columnSearch' }];

    this.saveGridSubscription = this.gridService
      .getSaveGridSubject()
      .subscribe(this.processSaveGridResponse.bind(this));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  selectedRowsChange(evt): void {
    this.selectedRows = evt;
  }

  changeOrigin(evt) {
    // Trick to change object reference and reflect it on ngOnChanges
    this.currentOrigin = this.formOrigins[evt.key].find((item) => item.origin === evt.value);
    let newGridProperties = Object.assign({}, this.gridProperties);

    this.panels.exportF9Form.disabled = !evt.value || !evt.value.includes('f9');

    newGridProperties.key = evt.key;
    newGridProperties.filterOrigin = evt.value;
    newGridProperties.origin = evt.value;
    newGridProperties.exportData = [
      <GridExportData>{
        origin: evt.value,
        title: evt.value,
        filterable: true,
        filename: `${evt.key}_${evt.value}`,
      },
    ];
    newGridProperties.reload = !!evt.value;
    newGridProperties.additionalSchemaData = this.schemaService.getDefaultSchema(
      newGridProperties.origin,
    );

    if (this.currentOrigin && ['circular', 'inventory', 'cnmc_a'].includes(evt.key)) {
      newGridProperties.title = this.currentOrigin.title;
    }

    this.gridProperties = Object.assign({}, newGridProperties);

    if (this.currentOrigin && this.currentOrigin.namespace === 'auditoria') {
      this.gridProperties.headerActions = [];
      if (evt.value === 'sabana_formularios_auditoria_importes_ajustados_vista') {
        if (evt.prev) {
          this.gridProperties.headerActions.push({
            type: 'auditory_back',
            requireSelected: false,
            requireFilter: true,
            fire: this.changeOrigin.bind(this, evt.prev),
          });
        }
      } else {
        this.gridProperties.headerActions.push({
          type: 'auditory_alerts',
          requireSelected: false,
          requireFilter: true,
          fire: this.switchToAuditoryAlerts.bind(this, evt.value, this.currentOrigin.title),
        });
      }
    }
  }

  switchToAuditoryAlerts(formName, formTitle) {
    this.investmentsFiltersService.filterForms(formTitle);
    this.changeOrigin({
      key: 'auditoria',
      value: 'sabana_formularios_auditoria_importes_ajustados_vista',
      prev: {
        key: 'auditoria',
        value: formName,
      },
    });
  }

  changeColumnSelection(evt) {
    this.gridProperties.additionalSchemaData = Object.assign({}, evt);
  }

  generateSheet(type) {
    this.tasksService.reloadSheet(type);
  }

  // Edit Functions

  dblClick(item): void {
    this.editingRows.add(item);
    this.setEditMode();
  }

  cancelEdit(): void {
    this.editingRows.clear();
    this.gridProperties.editingRows = Array.from(this.editingRows);
    this.editData = [];
    this.setEditMode();
  }

  setEditMode() {
    if (
      this.gridProperties.editingRows &&
      this.editingRows.size !== this.gridProperties.editingRows.length
    ) {
      this.gridProperties.editingRows = Array.from(this.editingRows);
    }
    this.editMode = this.gridProperties.editingRows && this.gridProperties.editingRows.length > 0;
    this.gridProperties.globalEditMode = this.editMode;
    this.gridProperties = Object.assign({}, this.gridProperties);
  }

  gridEditChange(eventObject) {
    if (!!this.gridProperties.reload) {
      this.gridProperties.reload = false;
    }

    if (eventObject.type === 'massiveChange') {
      this.editingRows = new Set(eventObject.object.map((item) => item));
      this.setEditMode();
    }

    if (this.editMode) {
      this.editData = eventObject.object;
      this.editType = eventObject.type;
      this.savingFlag = false;
      safeDetectChanges(this.cdr);
    }
  }

  private processSaveGridResponse(response) {
    if (response.type === 'ERROR') {
      this.savingFlag = true;
    } else {
      this.savingFlag = false;
      this.gridProperties.reload = true;
      this.cancelEdit();
    }
    this.showModalSaving = false;
    safeDetectChanges(this.cdr);
  }

  /**
   * Añadir, copiar y eliminar registros
   */

  createRows($event) {
    this.savingFlag = true;
    this.showModalSaving = true;

    var params = $event.rowToSend;

    let table = this.gridProperties.origin;

    let aggregateTable = this.gridProperties.origin.replace('_vista', '');

    let tableConfig = DEFAULT_VIEWS[table];

    this.addYearToEditDataIfInvisible(tableConfig);

    var pk = Object.keys(params)
      .filter((field) => tableConfig[field] && tableConfig[field].pk)
      .map((field) => {
        return { name: field, value: params[field] };
      })
      .reduce((o, item) => Object.assign(o, { [item.name]: item.value }), {});

    let amendments = [
      {
        table: aggregateTable,
        amendment_type: 'insert_master_editions',
        primary_keys: pk,
        rows: [params],
      },
    ];

    this.apiService.validateCopyAmendments(amendments).subscribe(
      (res) => {
        if (res['ids'] == null) {
          this.gridService.processSave(
            this.gridService.saveGridChanges(this.apiService.putAmendments.bind(this.apiService), [
              {
                amendments: amendments,
              },
            ]),
          );
        } else {
          const error = {
            type: 'error',
            message: `Los siguientes identificadores (${res['ids']}) ya existen. Por lo tanto, no se pueden crear de nuevo`,
            fixed: false,
            popup: true,
          };

          this.notificationService.add(error);
          this.savingFlag = false;
          this.showModalSaving = false;
        }
      },
      (err) => {
        this.notificationService.add({
          type: 'error',
          message: 'Ha ocurrido un error guardando las modificaciones.',
          fixed: false,
          popup: true,
        });
        throw new Error(`Ha ocurrido un error guardando las modificaciones: ${err.message}.`);
      },
    );
  }

  deleteRows($event) {
    this.savingFlag = true;

    this.showModalSaving = true;

    var params = $event;

    let table = this.gridProperties.origin;

    let manualTable = this.gridProperties.origin.replace('_vista', '');

    let tableConfig = DEFAULT_VIEWS[table];

    this.addYearToEditDataIfInvisible(tableConfig);

    var pk = Object.keys(params)
      .filter((field) => tableConfig[field] && tableConfig[field].pk)
      .map((field) => {
        return { name: field, value: params[field] };
      })
      .reduce((o, item) => Object.assign(o, { [item.name]: item.value }), {});

    let amendments = [
      {
        table: manualTable,
        amendment_type: 'delete_master_editions',
        primary_keys: pk,
        rows: params,
      },
    ];

    this.gridService.processSave(
      this.gridService.saveGridChanges(this.apiService.putAmendments.bind(this.apiService), [
        {
          amendments: amendments,
        },
      ]),
    );
  }

  copyRows($event) {
    this.savingFlag = true;

    this.showModalSaving = true;

    var params = $event;

    let table = this.gridProperties.origin;

    let manualTable = this.gridProperties.origin.replace('_vista', '');

    let tableConfig = DEFAULT_VIEWS[table];

    this.addYearToEditDataIfInvisible(tableConfig);

    var pk = Object.keys(params[0])
      .filter((field) => tableConfig[field] && tableConfig[field].pk)
      .map((field) => {
        return { name: field, value: params[field] };
      })
      .reduce((o, item) => Object.assign(o, { [item.name]: item.value }), {});

    let amendments = [
      {
        table: manualTable,
        amendment_type: 'copy_master_editions',
        primary_keys: pk,
        rows: params,
      },
    ];

    this.apiService.validateCopyAmendments(amendments).subscribe(
      (res) => {
        if (res['ids'] == null) {
          this.gridService.processSave(
            this.gridService.saveGridChanges(this.apiService.putAmendments.bind(this.apiService), [
              amendments,
            ]),
          );
        } else {
          const error = {
            type: 'error',
            message: `Los siguientes identificadores (${res['ids']}) ya existen. Por lo tanto, no se pueden copiar de nuevo`,
            fixed: false,
            popup: true,
          };

          this.notificationService.add(error);
          this.savingFlag = false;
          this.showModalSaving = false;
        }
      },
      (err) => {
        this.notificationService.add({
          type: 'error',
          message: 'Ha ocurrido un error guardando las modificaciones.',
          fixed: false,
          popup: true,
        });
        throw new Error(`Ha ocurrido un error guardando las modificaciones: ${err.message}.`);
      },
    );
  }

  //Applied on Select tags
  saveChanges($event) {
    if (this.editData && this.editData.length > 0) {
      this.savingFlag = true;
      this.showModalSaving = true;

      let table = this.gridProperties.origin.replace('_circular', '').replace('_vista', '');

      let tableConfig = DEFAULT_VIEWS[this.gridProperties.origin];

      let pks = Object.keys(tableConfig).filter((f) => tableConfig[f].pk);

      let componentIdField = pks.find((f) => !f.includes('anio_'));
      let yearField = pks.find((f) => f.includes('anio_'));

      this.mastersService.getRetributiveYear().subscribe((retributiveYear) => {
        this.authService.getProfile().subscribe((profile) => {
          let isMassive = this.editType === 'massiveChange';

          let isSingleQueryEdition = MASSIVE_EDITIONS_CONFIG.tables_single_query_edition.includes(
            this.gridProperties.origin,
          );

          let payload = undefined;

          let recurrentEdition = $event['recurrentEdition'] !== undefined ? $event['recurrentEdition'] : false;

          if (isMassive && isSingleQueryEdition) {
            const filters = this.investmentsFiltersService
              .getFilterByOrigin(this.gridProperties.origin)
              .map((currentFilter) => {
                currentFilter.filter.rules.forEach((rule) => delete rule['origin']);
                return getExpandedItem(currentFilter.filter, []);
              });

            const queryValue = JSON.stringify({
              filters: [{ rules: filters, condition: 'AND' }],
              logic: 'and',
            });

            let amendments = {};
            this.editData[0]['currentEditedFields'].forEach((editedField) => {
              amendments[editedField] = this.editData[0][editedField];
            });

            payload = {
              amendments: amendments,
              query: queryValue,
              table: this.gridProperties.origin,
              primary_key: componentIdField,
              year_field: yearField,
              recurrent_edition: recurrentEdition,
              observaciones: 'Edición masiva vía web',
              user: profile.email,
            };
          } else {
            payload = { amendments: [] };

            this.editData.forEach((editedData) => {
              editedData['currentEditedFields'].forEach((editedField) => {
                payload.amendments.push({
                  table: 'maestros_ediciones_manuales',
                  amendment_type: 'upsert',
                  primary_keys: {
                    anio_informacion: retributiveYear,
                    identificador: editedData[componentIdField],
                    tabla: table,
                    campo_edicion: editedField,
                  },
                  new_values: {
                    valor_edicion: editedData[editedField],
                    observaciones: isMassive ? 'Edición masiva vía web' : 'Edición puntual vía web',
                    usuario: profile.email,
                    edicion_recurrente: recurrentEdition,
                  },
                });
              });
            });
          }


          this.savingType = 'massiveSaving';
          this.showModalSaving = false;

          this.apiService.putAsyncAmendments(payload).subscribe((response) => {
            let asyncId = response.async_job_id;

            this.downloadsService.addDownload({
              id: response.async_job_id,
              fileName: 'Ejecutando modificaciones',
              url: '#',
              status: 'RUNNING',
            });

            let poller = this.apiService.jobQueryPoller(asyncId);
            poller.getObservable().subscribe((pollerResponse: AsyncQueryResponse) => {
              poller.complete();
              this.downloadsService.assignData(pollerResponse, asyncId);
              new Subject<AsyncQueryResponse>().next(pollerResponse);
              this.downloadsService.downloadReady = true;
              this.masterDataService.unloadAmendment(retributiveYear, 'maestros_ediciones_manuales').subscribe();
              return;
            });
          });

        });
      });
    }
  }

  private addYearToEditDataIfInvisible(tableConfig) {
    let invisibleYearField = Object.keys(tableConfig).find(
      (field) => field.includes('anio_') && tableConfig[field].pk && !tableConfig[field].visible,
    );

    if (invisibleYearField) {
      this.mastersService.getRetributiveYear().subscribe((retributiveYear) => {
        this.editData.forEach((data) => (data[invisibleYearField] = +retributiveYear));
      });
    }
  }
}
